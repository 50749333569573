import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import { Link } from "gatsby";
import { IconPeople } from "../atoms/Icons";
import ServicesContainer from "./widgets/ServicesContainer";

export const CardCabin = ({ image, title, slug, personas, data }) => {
  return (
    <div className=" rounded-t-lg shadow-lg ">
      <div className="rounded-t-lg flex items-center flex-col justify-center top-0 bottom-0 border w-96">
        <div className="">
          <GatsbyImage
            image={image}
            placeholder="tracedSVG"
            alt="image cabin"
            className=" w-96 rounded-t-md"
          />
        </div>
        <div className="flex flex-col items-center p-6 z-10 gap-2 rounded-xl">
          <p className="card-paragraph font-bold uppercase">{title}</p>
          <p className="card-paragraph flex items-center">
            <IconPeople />
            Capacidad {personas} Personas
          </p>
          {/* <div className="flex">
            <ServicesContainer data={data} size={"56"} />
          </div> */}
          <hr className="text-gray-400 bg-gray-400" />
          <div className="flex gap-4">
            <Link to={`/card/${slug}`} className="button-primary mx-auto px-8">
              VER MAS
            </Link>
            <Link to={`/contact`} className="button-primary mx-auto px-8">
              Reservar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardActivitiesHome = () => {
  return (
    <div className="max-w-lg  lg:px-0 rounded-lg ">
      <div className="block relative">
        <StaticImage
          src="../images/Home/Actividades.png"
          height={897}
          width={560}
          alt="activities picture card"
          placeholder="tracedSVG"
          layout="constrained"
          className="z-0 max-w-screen w-full"
        />
        <div className="absolute bottom-0 left-0 flex flex-col gap-4 z-10 pl-2 pb-3">
          <p className="paragraph text-xl pl-1 uppercase">
            Vive La <br /> Experiencia <br /> Nexpa
          </p>
          <Link to="/activities">
            <button className="button-primary opacity text-sm hover:opacity-100">
              Actividades para hacer
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const CardCabinHome = () => {
  return (
    <div className="max-w-lg  lg:px-0 rounded-lg ">
      <div className="block relative">
        <StaticImage
          src="../images/Home/Cabañas.png"
          height={434}
          width={560}
          alt="activities picture card"
          placeholder="tracedSVG"
          className="z-0 max-w-screen"
        />
        <div className="absolute bottom-0 left-0 flex flex-col gap-4 z-10 pl-2 pb-3">
          <p className="paragraph text-xl pl-1 uppercase">
            Tu casa <br /> en el <br /> paraiso
          </p>
          <Link to="/card">
            <button className="button-primary opacity text-sm hover:opacity-100">
              Conoce nuestras cabañas
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const CardRestaurantHome = () => {
  return (
    <div className="max-w-lg  lg:px-0 rounded-lg ">
      <div className="block relative">
        <StaticImage
          src="../images/Home/Gastronomia.png"
          height={434}
          width={560}
          alt="activities picture card"
          placeholder="tracedSVG"
          className="z-0 max-w-screen w-full -ml-1"
        />
        <div className="absolute bottom-0 left-0 flex flex-col gap-4 z-10 pl-2 pb-3">
          <p className="paragraph text-xl pl-1 uppercase">
            Disafruta de la <br /> Gastronomia <br /> Local
          </p>
          <Link to="/restaurant">
            <button className="button-primary opacity text-sm hover:opacity-100">
              Prueba nuestras comidas
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
