import React from "react";
import {
  Icon2CamasDobles,
  Icon3CamasDobles,
  Icon4CamasDobles,
  IconAC,
  IconBath,
  IconBeach,
  IconFun,
  IconKitchen,
  IconPet,
  IconPool,
  IconTV,
  IconWifi,
} from "../../../atoms/Icons";

const ServicesContainer = ({ data, className }) => {
  console.log(data);
  return (
    <div>
      <p className="paragraph font-bold uppercase pb-2 py-2">Comodidades</p>
      <div className="flex flex-wrap gap-4 items-center justify-center lg:justify-start max-w-lg lg:gap-4 py-2">
        {data.includes("2-camas") && <Icon2CamasDobles />}
        {data.includes("3-camas") && <Icon3CamasDobles />}
        {data.includes("4-camas") && <Icon4CamasDobles />}
        {data.includes("wifi") && <IconWifi />}
        {data.includes("tv") && <IconTV />}
        {data.includes("alberca") && <IconPool />}
        {data.includes("mascota") && <IconPet />}
        {data.includes("cocina") && <IconKitchen />}
        {data.includes("ventilador") && <IconFun />}
        {data.includes("vista") && <IconBeach />}
        {data.includes("baño") && <IconBath />}
        {data.includes("clima") && <IconAC />}
      </div>
    </div>
  );
};

export default ServicesContainer;

// (i === "2-camas" && <Icon2CamasDobles />) ||
// (i === "3-camas" && <Icon3CamasDobles />) ||
// (i === "4-camas" && <Icon4CamasDobles />) ||
// (i === "ventilador" && <IconFun />) ||
// (i === "clima" && <IconAC />) ||
// (i === "cocina" && <IconKitchen />) ||
// (i === "baño" && <IconBath />) ||
// (i === "alberca" && <IconPool />) ||
// (i === "vista" && <IconBeach />) ||
// (i === "mascota" && <IconPet />) ||
// (i === "wifi" && <IconWifi />);
